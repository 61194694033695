<template>
  <Account :page="page" :size="size" :filter="filter" :tab="tab" />
</template>

<script>
import Account from '@/components/account/Account/Account';
export default {
  name: 'AccountView',
  components: {
    Account,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    tab: { type: String, default: 'account' },
    filter: { type: Object }
  },
}
</script>
